.center{
    flex: 7;
    padding: 20px;
}

.add-post-container{
    width: 100%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    -webkit-box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    -moz-box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    
}

.add-post-top{
    align-items: center;
    display: flex;
    
    gap: 20px;
}

.add-post-input{
    border: none;
    width: 80%;
    resize: none;
    
}

.add-post-input:focus{
    outline: none;
}

.add-post-top > img{
    width: 56px;
    height: 56px;
    border-radius: 50%;
    object-fit: cover;
}

.add-post-hr{
    width: 90%;
    margin: 20px auto;
}

.add-post-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 30px;
}

.add-post-options{
    display: flex;
    gap: 10px;
    /* margin-left: 20px; */
    /* margin-top: 30px; */
}

.add-post-option {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-left: 30px;
    cursor: pointer;
}

.add-post-option > label{
    
    cursor: pointer;
    gap: 3px;
    display: flex;
}

.share-btn{
    width: 70px;
    height: 40px;
    /* padding: 5px 10px; */
     background-color: hsl(120, 30%, 44%);
     color: white;
     border: 1px solid white;
     border-radius: 10px;
}

.preview-container{
    position: relative;
    display: flex;
    justify-content: flex-start;
}

#preview{
    max-width: 100%;
    height: 300px;
    object-fit: contain;
}

.cancel{
    position: absolute;
    top: 10px;
    left: 10px;
}

@media only screen and (max-width: 850px) {
    .center{
        max-width: 100%;
    }
}