.register-container {
    background-color: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 100px;
    height: 100vh;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
}

.register-title {
    color: rgb(78, 78, 255);
    font-size: 70px;
}

.register-text {
    font-size: 30px;
}

.register-credentials {
    background-color: white;
    width: 400px;
    padding: 40px 0;
    /* height: 400px; */
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.register-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

}

.register-form>input {
    font-size: 20px;
    padding: 10px;
}

.btn-register {
    width: 100px;
    height: 50px;
    background-color: rgb(54, 253, 54);
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 20px;
}

.register-login{
    color: blue;
}

@media only screen and (max-width: 1250px) {
    .register-container {
        flex-direction: column;
    }

    .register-heading {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .register-text {
        text-align: center;
    }
}