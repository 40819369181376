.profile-top-container{
    margin-top: 80px;
    width: 70%;
    background-color: white;
    padding-bottom: 10px;
}

.images{
    height: 400px;
    position: relative;
}

.cover-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.profile-image{
    width: 200px;
    height: 200px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 95%;
    border-radius: 50%;
    
    object-fit: cover;
    background-color: white;
}

.user-info{
    margin: 0 auto;
    margin-top: 100px;
    width: 400px;
    text-align: center;
}

@media only screen and (max-width: 1000px){
    .profile-top-container{
        width: 90%;
    }
}

@media only screen and (max-width: 550px) {
    .profile-top-container {
        width: 100%;
    }
}