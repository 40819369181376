@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Merriweather', serif;
}

li{
    list-style: none;
}

a{
    text-decoration: none;
}

