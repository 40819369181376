.post-container {
    width: 100%;
    /* height: 500px; */
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    -webkit-box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    -moz-box-shadow: 1px 0px 4px 2px rgba(212, 212, 212, 0.72);
    padding: 20px;
}

.post-top-container {
    display: flex;
    justify-content: space-between;
}

.post-top {
    display: flex;
    align-items: center;
    gap: 10px;
}

.post-user-image {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}

.post-username {
    font-size: 20px;
    font-weight: 600;

}

.post-time {
    font-size: 15px;
}

.post-description{
    margin-top: 20px;
    font-size: 20px;
}

.post-image {
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    object-fit: contain;

}

.post-bottom {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.post-counts {
    margin-left: 10px;
    display: flex;
    width: 50%;
    justify-content: space-between;
}

.post-attributes {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.287);
    border-bottom: 1px solid rgba(0, 0, 0, 0.287);
    padding: 10px 0;
}

.attribute {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    user-select: none;
}