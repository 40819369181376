.leftbar-friends {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.leftbar-friend {
    display: flex;
    gap: 20px;
    display: flex;
    align-items: center;
}

.leftbar-friend>img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}