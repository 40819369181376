.container{
    display: flex;
    width: 100%;
    min-height: calc(100vh - 80px);
    background-color: #f1f1f1;
    margin-top: 80px;
}

.sidebar{
    transition: all .5s linear;
}

