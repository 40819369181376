.profile-intro-container{
    flex: 4;
    height: max-content;
    background-color: white;
    margin: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 5px;
    position: sticky;
    top: 0;
}

.user-friend-heading{
    margin-top: 50px;
}

.user-friends{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    
}

.user-friend{
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

@media only screen and (max-width: 1000px) {
    .profile-intro-container{
        position: relative;
        
    }
}

@media only screen and (max-width: 550px){
    .profile-intro-heading,
    .user-friend-heading{
        font-size: 20px;
    }
}