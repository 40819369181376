.profile-container{
    display: flex;
    align-items: center;
    background-color: #f1f1f1;
    flex-direction: column;
}

.profile-center{
    display: flex;
    width: 70%;
}

@media only screen and (max-width: 1000px) {
    .profile-center{
        flex-direction: column;
        width: 80%;
    }
}

@media only screen and (max-width: 550px) {
    .profile-center {
        width: 100%;
        /* margin: 10px; */
    }
}